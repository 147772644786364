
<template>
	<div>
		{{ mensagem }}
	</div>
</template>

<script>
import http from '@commons/http';

export default {
	name: "StatusMSFinanceiro",
	components:{},
	data() {
		return {
			statusSiga: 'Carregando...',
			statusMS: 'Carregando...',
			statusVINDI: 'Carregando...',
			jsonStatus: {
				statusSiga: 'Carregando...',
				statusMS: 'Carregando...',
				statusVINDI: 'Carregando...'
			},
			mensagem: 'Carregando...'
		}
	},
	async created() {
		this.getStatus();
	},
	methods:{
		async getStatus() {
			try {
				const response = await http.get('/v1/system/status');
				if (response.data) {
					this.statusSiga = response.data.status;
					this.statusMS = response.data.statusMSFinanceiro;
					this.statusVINDI = response.data.vindiStatus;
				} else {
					this.statusSiga = 'ERRO';
					this.statusMS = 'ERRO';
					this.statusVINDI = 'ERRO';
				}
			} catch (error) {
				this.statusSiga = 'ERRO';
				this.statusMS = 'ERRO';
				this.statusVINDI = 'ERRO';
			}

			this.jsonStatus = JSON.stringify({
				siga: this.statusSiga,
				msfinanceiro: this.statusMS,
				vindi: this.statusVINDI
			});

			if (this.statusSiga != 'OK') {
				this.mensagem = 'Erro SIGA API';
			} else if (this.statusMS != 'OK') {
				this.mensagem = 'Erro MSFinanceiro';
			} else if (this.statusVINDI != 'OK') {
				this.mensagem = 'Erro VINDI';
			} else if (
				this.statusSiga == 'OK' &&
				this.statusMS == 'OK' &&
				this.statusVINDI == 'OK'
			) {
				this.mensagem = 'Sucesso';
			} else {
				this.mensagem = 'Falha na verifica��o';
			}
		}
	}
}
</script>
